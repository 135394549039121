import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Content from './content'
import ServicesItem, { ServicesItemWrapper } from './services-item'
import ServicesBubble from './services-bubble'
import { useScroll } from '../utils/useScroll'
import styled from 'styled-components'
import { InnerImage } from './hover-image'
import { Background } from './contacts-background'

const ServicesWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: 900px) {
    ${Background} {
      display: none;
    }
  }
`

const ServicesListWrapper = styled.div`
  @media (max-width: 900px) {
    display: flex;
    width: 100%;
    overflow: auto;
  }
`
const ServicesList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: 900px) {
    flex-wrap: nowrap;
    justify-content: flex-start;

    ${ServicesItemWrapper} {
      width: calc(100vw - 60px);
      max-width: 350px;
      box-sizing: border-box;
      margin-bottom: 80px;

      position: relative;
      z-index: 1;
      background: #fff;
      transition: all 0.2s ease-in;
      box-shadow: 0 20px 50px rgba(0, 0, 0, 0.13);

      ${InnerImage}:last-child {
        transition: all 0.2s ease-in;
        opacity: 1;
      }
    }
  }
`

const Services = ({ tagToggle, id }) => {
  const scrollTo = useScroll()

  const data = useStaticQuery(graphql`
    query ServicesQuery {
      allServicesJson(filter: { image: { ne: null } }) {
        edges {
          node {
            title
            tag
            image
            description
          }
        }
      }
    }
  `)

  return (
    <ServicesWrapper id={id}>
      <ServicesBubble x={-110} y={670} />
      <Content style={{ marginTop: '140px' }}>
        <h2>Услуги</h2>
        <ServicesListWrapper>
          <ServicesList>
            {data.allServicesJson.edges.map(({ node }) => (
              <ServicesItem
                key={node.title}
                title={node.title}
                image={node.image}
                description={node.description}
                onClick={() => {
                  scrollTo('Галерия')
                  tagToggle(node.tag)
                }}
              />
            ))}
          </ServicesList>
        </ServicesListWrapper>
      </Content>
    </ServicesWrapper>
  )
}

export default Services
