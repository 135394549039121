import React from "react"
import HoverImage, { InnerImage } from "./hover-image"
import styled from "styled-components"

export const ServicesItemWrapper = styled.div`
  display: flex;
  width: 305px;
  text-align: center;
  align-items: center;
  font-size: 0.78rem;
  line-height: 1.3;
  flex-direction: column;
  padding: 30px 30px 5px;
  margin: 0 30px 25px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0s ease-in;

  &:hover {
    position: relative;
    z-index: 1;
    background: #fff;
    transition: all 0.2s ease-in;
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.13);

    ${InnerImage}:last-child {
      transition: all 0.2s ease-in;
      opacity: 1;
    }
  }
`

const ServicesItem = ({ title, image, description, onClick }) => (
  <ServicesItemWrapper onClick={onClick}>
    <div style={{ width: "160px" }}>
      <HoverImage inactiveSrc={image} activeSrc={`color_${image}`} />
    </div>
    <h3
      style={{
        whiteSpace: "pre-wrap",
        minHeight: "48px",
      }}
    >
      {title}
    </h3>
    <p>{description}</p>
  </ServicesItemWrapper>
)

export default ServicesItem
