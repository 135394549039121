import React, {
  useState,
  useEffect,
  useCallback,
  useReducer,
  useMemo,
} from "react"
import styled from "styled-components"
import Carousel, { Modal, ModalGateway } from "react-images"
import useGallery from "../utils/useGallery"
import Button from "./button"

const Img = styled.div`
  width: 100%;
  padding-top: ${props => props.pt};
  background-image: url(${props => props.src});
  background-size: cover;
`
const Box = styled.div`
  display: inline-block;
  vertical-align: middle;
  transition: filter 0.3s;
  filter: brightness(80%);
  :hover {
    filter: brightness(100%);
  }
  width: ${props => props.width[0]};

  @media (max-width: 1279px) {
    width: ${props => props.width[1]};
  }

  @media (max-width: 768px) {
    width: ${props => props.width[2]};
  }
`

const Gallery = ({ itemsPerRow: itemsPerRowByBreakpoints = [1], tag }) => {
  const [showAll, setShowAll] = useState(false)

  useEffect(() => {
    setShowAll(false)
  }, [tag])

  const [data, rowAspectRatioSumsByBreakpoints] = useGallery(
    itemsPerRowByBreakpoints,
    showAll,
    tag
  )

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalCurrentIndex, setModalCurrentIndex] = useState(0)

  const closeModal = useCallback(() => setModalIsOpen(false), [])
  const openModal = useCallback(imageIndex => {
    setModalCurrentIndex(imageIndex)
    setModalIsOpen(true)
  }, [])

  const images = showAll ? data : data.slice(0, 9)

  return (
    <>
      <div>
        {images.map((image, i) => (
          <a
            key={i}
            href={image.src}
            onClick={e => {
              e.preventDefault()
              openModal(i)
            }}
          >
            <Box
              width={rowAspectRatioSumsByBreakpoints.map(
                (rowAspectRatioSums, j) => {
                  const rowIndex = Math.floor(i / itemsPerRowByBreakpoints[j])
                  const rowAspectRatioSum = rowAspectRatioSums[rowIndex]

                  return `${(image.width / image.height / rowAspectRatioSum) *
                    100}%`
                }
              )}
            >
              <Img
                src={image.src}
                pt={`${(image.height / image.width) * 100}%`}
              />
            </Box>
          </a>
        ))}

        <ModalGateway>
          {modalIsOpen ? (
            <Modal onClose={closeModal}>
              <Carousel
                views={images.map(({ src }) => ({
                  src: src,
                }))}
                currentIndex={modalCurrentIndex}
                components={{ FooterCount: () => null }}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
      {!showAll && images.length > 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "40px",
          }}
        >
          <Button onClick={() => setShowAll(true)}>Зареди още</Button>
        </div>
      )}
    </>
  )
}

export default Gallery
