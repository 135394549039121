import React from "react"
import { Background } from "./contacts-background"

const GalleryBottomBubble = props => (
  <Background {...props} align="left">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="394"
      height="394"
      viewBox="0 0 394 394"
    >
      <circle
        cx="197"
        cy="197"
        r="197"
        opacity="0.3"
        fill="url(#linear-gradient)"
      />
    </svg>
  </Background>
)

export default GalleryBottomBubble
