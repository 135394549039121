import React from "react"
import { Background } from "./contacts-background"

const ServicesBubble = props => (
  <Background {...props} align={"left"}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="466"
      height="561"
      viewBox="0 0 466 561"
    >
      <g transform="translate(-68 -1123)">
        <circle
          cx="210"
          cy="210"
          r="210"
          transform="translate(114 1264)"
          opacity="0.3"
          fill="url(#linear-gradient)"
        />
        <circle
          cx="110"
          cy="110"
          r="110"
          transform="translate(68 1123)"
          opacity="0.3"
          fill="url(#linear-gradient)"
        />
      </g>
    </svg>
  </Background>
)

export default ServicesBubble
