import React from "react"

import Layout from "../components/layout"
import Services from "../components/services"
import SEO from "../components/seo"
import Home from "../components/home"
import GallerySection from "../components/gallery-section"
import Contacts from "../components/contacts"
import { ScrollSpy } from "organism-react-scroll-nav"
import { useTagReducer } from "../components/tags"

const IndexPage = () => {
  const [tag, toggle] = useTagReducer()
  return (
    <Layout tagToggle={toggle}>
      <SEO title="" />
      <Services id="Услуги" tagToggle={toggle} />
      <GallerySection id="Галерия" tag={tag} toggle={toggle} />
      <Contacts id="Контакти" />
    </Layout>
  )
}

export default IndexPage
