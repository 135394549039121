import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Gallery from "./gallery"
import Content from "./content"
import GalleryBubble from "./gallery-bubble"
import Tags from "./tags"
import GalleryBottomBubble from "./gallery-bottom-bubble"
import styled from "styled-components"

export const GalleryWrapper = styled.div`
  margin-top: 90px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;

  ${Content} {
    align-items: stretch;
    padding: 70px;
  }

  @media (max-width: 900px) {
    ${Content} {
      padding: 25px;
    }
  }
`

const GallerySection = ({ tag, toggle, id }) => {
  return (
    <GalleryWrapper id={id}>
      <GalleryBubble y={-80} x={430} />
      <GalleryBottomBubble bottom={"130px"} x={-100} />
      <Content style={{}}>
        <h2 style={{ textAlign: "center" }}>Галерия</h2>
        <Tags selected={tag.selected} onClick={toggle} />
        <Gallery itemsPerRow={[3, 2, 1]} tag={tag.selected} />
      </Content>
    </GalleryWrapper>
  )
}

export default GallerySection
