import { useState, useEffect } from "react"

let cachedImages = {}

const chunk = (array, groupSize) => {
  const groups = []
  for (let i = 0; i < array.length; i += groupSize) {
    groups.push(array.slice(i, i + groupSize))
  }
  return groups
}

const sum = array =>
  array.reduce((accumulator, currentValue) => accumulator + currentValue)

const useGallery = (itemsPerRowByBreakpoints, all, tag) => {
  const [images, setImages] = useState([])
  const [imagesData, setImagesData] = useState([])
  const [
    rowAspectRatioSumsByBreakpoints,
    setRowAspectRatioSumsByBreakpoints,
  ] = useState([])

  useEffect(() => {
    const aspectRatios = imagesData.map(image => image.width / image.height)
    const _rowAspectRatioSumsByBreakpoints = itemsPerRowByBreakpoints.map(
      itemsPerRow =>
        chunk(aspectRatios, itemsPerRow).map(rowAspectRatios =>
          sum(rowAspectRatios)
        )
    )
    setRowAspectRatioSumsByBreakpoints(_rowAspectRatioSumsByBreakpoints)
  }, [imagesData])

  useEffect(() => {
    const data = []
    const _filtered = images.filter(({ tags }) =>
      tag ? (tags || []).indexOf(tag) > -1 : true
    )
    const _images = all ? _filtered : _filtered.slice(0, 9)
    if (!_images.length) {
      setImagesData([])
    }
    _images.forEach(({ url }) => {
      if (cachedImages[url]) {
        data.push(cachedImages[url])
        if (data.length === _images.length) {
          setImagesData(data)
        }
        return
      }

      const img = new Image()
      img.onload = () => {
        cachedImages[url] = img
        data.push(img)
        if (data.length === _images.length) {
          setImagesData(data)
        }
      }
      img.src = url
    })
  }, [images, all, tag])

  useEffect(() => {
    const getImages = async () => {
      const response = await fetch("/imagekit.php")
      const images = await response.json()

      setImages(images)
    }

    getImages()
  }, [])

  return [imagesData, rowAspectRatioSumsByBreakpoints]
}

export default useGallery
