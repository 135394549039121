import React, { useState, useEffect, useCallback } from 'react'
import Content from './content'
import Button from './button'
import Address from './address'
import ContactsBackground from './contacts-background'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

const ContactsContent = styled(Content)`
  position: relative;
  flex-direction: row;
  padding: 0 105px 20px;

  @media (max-width: 415px) {
    flex-direction: column;
  }
  @media (max-width: 900px) {
    padding: 25px;
  }
`

const Box = styled.div`
  display: flex;
  border-radius: 10px;
  width: 585px;
  align-items: center;
  flex-direction: column;
  padding: 75px 0;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.13);

  @media (max-width: 900px) {
    width: 100%;
    padding: 0;
    box-shadow: none;
  }
`

const Placeholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 540px;
  flex-direction: column;
  text-align: center;
  padding: 30px;
  font-size: 0.78rem;

  .la-ball-spin-rotate {
    color: #ff824e;
  }
`

const FieldBox = styled.div`
  width: 340px;

  @media (max-width: 415px) {
    width: 100%;
  }
`

const FilledTextField = withStyles({
  root: {
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#FF824E',
    },
    '& .MuiFilledInput-underline:after': {
      borderBottomColor: '#FF824E',
    },
    '& .MuiFilledInput-underline.Mui-error:after': {
      borderBottomColor: '#f44336',
    },
  },
})(TextField)

const required = (v) => !!v
const requiredIf = (c) => (c ? required : () => true)
const email = (v) => /[^@]+@[^\.]+\..+/.test(v)
const validations = {
  име: [required],
  _replyto: [required, email],
  тема: [required],
  'друга тема': [(v, vs) => requiredIf(vs['тема'] === 'Друго')(v)],
  съобщение: [required],
}

const Contacts = ({ id }) => {
  const [values, setValues] = useState({
    _subject: 'Заявка',
  })
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(null)
  const [touched, setTouched] = useState({})

  const clear = useCallback(() => {
    setValues({ _subject: 'Заявка' })
    setTouched({})
    setErrors({})
    setLoading(null)
  })

  const submitForm = useCallback(
    async () => {
      setLoading(true)
      const data = new URLSearchParams()
      Object.keys(values).forEach((key) => {
        data.append(key, values[key])
      })
      try {
        await fetch('https://email-service.hpstuff.now.sh/to/sofar', {
          method: 'POST',
          body: data,
        })
      } catch (e) {
        console.log(e)
      }
      setLoading(false)
    },
    [values]
  )

  const handleChange = (name) => (event) => {
    setTouched({ ...touched, [name]: true })
    setValues({ ...values, [name]: event.target.value })
  }

  useEffect(
    () => {
      const _errors = {}
      Object.keys(validations).forEach((key) => {
        if (validations[key].includes(require) && !values[key]) {
          _errors[key] = !valid
          return
        }
        const valid = validations[key].every((f) => f(values[key], values))
        if (!valid) {
          _errors[key] = !valid
        }
      })

      setErrors(_errors)
    },
    [values]
  )

  const data = useStaticQuery(graphql`
    query {
      allServicesJson {
        edges {
          node {
            title
          }
        }
      }
    }
  `)

  return (
    <ContactsContent id={id}>
      <ContactsBackground y={-500} x={245} />
      <Box>
        <h2 style={{ textTransform: 'none' }}>Контактна форма</h2>
        {loading ? (
          <Placeholder>
            <div className="la-ball-spin-rotate la-3x">
              <div />
              <div />
            </div>
          </Placeholder>
        ) : loading === null ? (
          <>
            <FieldBox
              as={FilledTextField}
              required
              error={touched['име'] && errors['име']}
              label="Име"
              margin="normal"
              variant="filled"
              value={values['име']}
              onBlur={handleChange('име')}
              onChange={handleChange('име')}
            />
            <FieldBox
              as={FilledTextField}
              required
              error={touched['_replyto'] && errors['_replyto']}
              label="Е-мейл"
              margin="normal"
              variant="filled"
              value={values._replyto}
              onBlur={handleChange('_replyto')}
              onChange={handleChange('_replyto')}
            />
            <FieldBox
              as={FilledTextField}
              label="Телефон"
              margin="normal"
              variant="filled"
              value={values['телефон']}
              onBlur={handleChange('телефон')}
              onChange={handleChange('телефон')}
            />
            <FieldBox
              as={FilledTextField}
              select
              required
              error={touched['тема'] && errors['тема']}
              label="Тема"
              margin="normal"
              variant="filled"
              value={values['тема']}
              onBlur={handleChange('тема')}
              onChange={handleChange('тема')}
            >
              {[
                ...data.allServicesJson.edges.map(({ node }) => (
                  <MenuItem key={node.title} value={node.title}>
                    {node.title}
                  </MenuItem>
                )),
                <MenuItem key={'Друго'} value={'Друго'}>
                  {'Друго'}
                </MenuItem>,
              ]}
            </FieldBox>
            {values['тема'] === 'Друго' && (
              <FieldBox
                as={FilledTextField}
                required
                error={touched['друга тема'] && errors['друга тема']}
                label="Друга тема"
                value={values['друга тема']}
                onBlur={handleChange('друга тема')}
                onChange={handleChange('друга тема')}
                margin="normal"
                variant="filled"
              />
            )}
            <FieldBox
              as={FilledTextField}
              required
              error={touched['съобщение'] && errors['съобщение']}
              label="Съобщение"
              multiline
              rowsMax="10"
              rows="3"
              margin="normal"
              variant="filled"
              value={values['съобщение']}
              onBlur={handleChange('съобщение')}
              onChange={handleChange('съобщение')}
            />
            <input
              type={'hidden'}
              value={values['_honeypot']}
              onChange={handleChange('_honeypot')}
            />
            <input hidden value={values['_honeypot']} onChange={handleChange('_honeypot')} />
            <div style={{ marginTop: '30px' }}>
              <Button disabled={Object.keys(errors).length} as="button" onClick={submitForm}>
                Изпрати
              </Button>
            </div>
          </>
        ) : (
          <Placeholder>
            <h3>
              {'Вашето съобщение'}
              <br />
              {'беше изпратено успешно.'}
            </h3>
            <p style={{ marginTop: '10px' }}>{'Ще се свържем с вас възможно най-скоро.'}</p>
            <div style={{ marginTop: '70px' }}>
              <Button onClick={clear}>Добре</Button>
            </div>
          </Placeholder>
        )}
      </Box>
      <Address />
    </ContactsContent>
  )
}

export default Contacts
