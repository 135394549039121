import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

export const InnerImage = styled(Img)``

const ImageWrapper = styled.div`
  position: relative;
  ${InnerImage}:last-child {
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
  }
`

const HoverImage = ({ activeSrc, inactiveSrc }) => (
  <StaticQuery
    query={graphql`
      query {
        allImageSharp {
          edges {
            node {
              fluid(maxWidth: 320) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    `}
    render={data => {
      const activeImage = data.allImageSharp.edges.find(
        ({ node }) => node.fluid.src.split("/").pop() === activeSrc
      )
      const inactiveImage = data.allImageSharp.edges.find(
        ({ node }) => node.fluid.src.split("/").pop() === inactiveSrc
      )
      if (!activeImage || !inactiveImage) {
        return <div />
      }
      return (
        <ImageWrapper>
          <InnerImage fluid={inactiveImage.node.fluid} />
          <InnerImage fluid={activeImage.node.fluid} />
        </ImageWrapper>
      )
    }}
  />
)

export default HoverImage
