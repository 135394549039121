import React from "react"
import styled from "styled-components"

const Label = styled.h4`
  color: #ff824e;
  font-weight: normal;
  font-size: 18px;
  margin-bottom: 8px;
`

const Description = styled.p`
  color: #9fa0a1;
  font-size: 16px;
  line-height: 1.5;
`

const AddressWrapper = styled.div`
  padding: 100px 110px 0;

  @media (max-width: 900px) {
    padding: 100px 60px 0;
  }
`

const Address = () => (
  <AddressWrapper>
    <Label>Адрес:</Label>
    <Description>
      София,
      <br />
      бул. Асен Йорданов №12
    </Description>
    <Label>Е-мейл:</Label>
    <Description>
      office@sofar-bg.com
      <br />
      sofar@mail.bg
    </Description>
    <Label>Телефон за връзка:</Label>
    <Description>+359 888 673 863</Description>
  </AddressWrapper>
)

export default Address
