import React from "react"
import { Background } from "./contacts-background"

const GalleryBubble = props => (
  <Background {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="626"
      height="626"
      viewBox="0 0 626 626"
    >
      <circle
        cx="313"
        cy="313"
        r="313"
        opacity="0.3"
        fill="url(#linear-gradient)"
      />
    </svg>
  </Background>
)

export default GalleryBubble
