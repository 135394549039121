import React, { useReducer } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { Flex, Box } from 'rebass'

const TagsWrapper = styled.div`
  @media (max-width: 900px) {
    display: flex;
    overflow: auto;
    width: 100vw;
    margin: 0 -25px;
    padding: 0 25px;
  }
`

const TagsList = styled(Flex)`
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  margin-left: -4px;
  margin-right: -4px;
  margin-bottom: 40px;

  @media (max-width: 900px) {
    flex-wrap: nowrap;
  }
`

const initialState = { selected: null }

function reducer(state, action) {
  switch (action.type) {
    case 'add':
      return { selected: action.content }
    case 'remove':
      return { selected: null }
    default:
      throw new Error()
  }
}

export const useTagReducer = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const toggle = (tag) => {
    if (state.selected === tag) {
      dispatch({ type: 'remove', content: tag })
    } else {
      dispatch({ type: 'add', content: tag })
    }
  }

  return [state, toggle]
}

export const Tag = styled(Box)`
  display: inline-block;
  color: #222;
  font-size: 20px;
  text-decoration: none;
  white-space: nowrap;

  ${({ selected }) =>
    selected &&
    `
    color: #fff;
    background-color: #ff824e;
    box-shadow: 0 10px 30px rgba(255, 130, 78, 0.4);
  `}
  padding: 5px 18px;
  margin: 10px 4px;
  border-radius: 100000px;
`

const Tags = ({ selected, onClick }) => {
  const data = useStaticQuery(graphql`
    query TagsQuery {
      allServicesJson(filter: { tag: { ne: null } }) {
        edges {
          node {
            tag
            tagName
          }
        }
      }
    }
  `)

  return (
    <TagsWrapper>
      <TagsList as={'ul'}>
        <Tag
          as={'a'}
          href="#"
          selected={!selected}
          onClick={(e) => {
            e.preventDefault()
            onClick(null)
          }}
        >
          {'всички'}
        </Tag>
        {data.allServicesJson.edges.map(({ node }) => (
          <Tag
            key={node.tag}
            as={'a'}
            href="#"
            selected={node.tag === selected}
            onClick={(e) => {
              e.preventDefault()
              onClick(node.tag)
            }}
          >
            {node.tagName}
          </Tag>
        ))}
      </TagsList>
    </TagsWrapper>
  )
}

export default Tags
